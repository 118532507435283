.backDrop{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 13;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.5s ease-in-out all;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.644);
}

.modalDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 13;
    width: 100%;
    height: 100%;
}
.fadeIn{

    animation: openModal ease-in-out 0.5s forwards;
}
.fadeOut{
    animation: closeModal ease-in-out 0.5s forwards;

}
@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);

    }
    50% {
        opacity: 0;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes closeModal {
    0% {

        opacity: 1;
        transform: translateY(0%);

    }
    50% {
        opacity: 0;
        transform: translateY(0%);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.modalBoarder{
    width: 320px;
    max-width: 100%;
    transition: 0.5s ease-in-out all;
    z-index: 16;
    border-top: aliceblue;
    height: 319px;
    border-radius: 20px;
    border-bottom: #1043A9 6px solid;
    background-color: rgb(238, 238, 238);
    max-height: 100%;

}
.modalBoarder h4{
    font-size: 22px;
    text-align: center;
    margin-top: 17px;
    font-family: Dana1;
    margin-bottom: 15px;
}
.modalSymbol{
    display: flex;
    align-items: center;
    text-align: center;
    height: 55%;
}
.msg{
    text-align: center;
}
.msg h3{
 color: #354063;
 font-family: Dana1;
 font-size: 27px;
}
.btnsDiv{
    margin-top: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    
    
}
.btnDiv{
    margin-left: auto;
    margin-right: 10px;
}
.btnDiv2{
    margin-left: 10px;
    margin-right: auto;
}
.scaleIn{ transform: scale(1.0);}
.scaleOut{transform: scale(0.0);}

.opratorDiv{
    background-color: #d8d8d8;
    border-radius: 8px;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0px 10px 10px 10px !important;
}
.opratorDiv h3{
    color: #354063;
    font-size: 16px;
    padding-top: 8px;

}
.numbersDiv{
    font-size: 17px;
    color: #d8d8d8;
    border-radius: 8px;
    background-color: #1043A9;
    padding: 12px 0px 7px 0px;
    
}
.num1{
    margin-right: 10px;
    font-size: 19px;
}
.num2{
    margin-left: 10px;
    font-size: 19px;

}

.callPhoneNumber{
    border:#7fa5f1 3px solid ;
    background-color: rgb(235, 235, 235);
    padding: 6px 10px 6px 10px;
    width: 97%;
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 5px;
}
.numbersDiv a{
    color: rgb(241, 241, 241);
    box-shadow: none;
    transition: 0.2s all ease-in-out;
    text-decoration: none ;
    
}
.numbersDiv a:hover{
    color: rgb(187, 187, 187);
    box-shadow: none;
    text-decoration: none ;
    
}