.input{
    border: solid #adc8ff 3px;
    font-size: 18px;
    text-align: right;
    padding:5px 7px 5px 0px;
    border-radius: 5px;
    color: #354063;
    width: 100%;
    transition: 0.1s ease-in-out all;

}

.input:focus{
    outline: none;
    -webkit-box-shadow: 0px 0px 0px 4px rgba(204,217,247,0.99); 
    box-shadow: 0px 0px 0px 4px rgba(204,217,247,0.99);
}
.input:placeholder-shown{
color: #202020;

}
.lable{
    
}
.inputDiv{
    width: 100%;
}