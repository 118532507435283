.commentSection{
    background-color: #EFEFEF;
    border-radius: 8px;
}
.commentHeaderSection{
    padding-top: 25px;
    
    }
.commentCounter{
    text-align: right;
    float: right;
    padding-right: 50px;
    display: inline-block;
}
.charCounter{
    float: left;
    padding-left: 50px;
    display: inline-block;
}
.commentCounter h3 {
    color: #354063;
    font-size: 20px;
    padding-bottom: 10px;

}
.commentCounter h3 span {
    color: #354063;
}
.charCounter h3{
    color: #354063;
    font-size: 20px;
}
.charCounter h3 span{
    color: #1043A9;
}

.commentItself{
    text-align: center;
    margin-top: 15px;
}

.commentTextarea{
    background-color: #E6EDFD;
    border-color: #354063; 
    -webkit-border-radius: 25px;
    border-width: 3px;
    -moz-border-radius: 25px;
    color: #354063 !important;
    padding: 10px !important;
    border-radius: 8px;

}
.commentTextarea:active{
    background-color: #E6EDFD;

}
.commentTextarea:focus{
    background-color: #E6EDFD;

}
.textareaDiv{
    padding: 0px 35px 0px 35px;
}
.commentFooter input{
    border-color: #1043A9;
    display: inline-block;
    
}
.commentFooter{
    margin-top: 15px;
    text-align: center;
    
}
.commentFooter h4{
    color: #354063;
    display: inline-block;
    font-size: 15px;
    padding-right: 10px;
    
}
.commentSendBtn{
    display: block;
    margin-top: 8px;
    padding-bottom: 10px;
}
.commentSection h4{
    color: #354063;

}
.commentTitle{
    text-align: justify;
    font-size: 37px;
    font-family: Dana1;
    line-height: 37px;
    margin-right: 30px;
    padding-bottom: 8px;
    color: #F38033;
}
    .commentSectionCol{

    }
