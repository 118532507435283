.noDataDiv{
    text-align: center;
}
.img{
    width: 200px;
}
.msg{
    margin-top: 18px;
    font-family: Dana1;
    font-size: 24px;
    color: #9194a0;

}