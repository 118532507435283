.topRightDivHeaderContainer{
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    margin-right: 10px;
    

}
.topLeftDivHeaderContainer{
    margin-top: 20px;
    display: flex;
    width: 100%;
    
}

.multiLangDiv{
    margin-left: 25px;
    margin-right: auto;    
}
.headerTitleDiv{
    margin-top: 4px;
    display: inline-block;
    margin-right: 8px;
}

.switchBtnDiv{
    display: inline-block;
    float: left;
    margin-right: auto;
    margin-left: 100px;
}


.lineDiv{
    width: 96%;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    background-color: #354063;
    margin-top: 15px;
    margin-bottom: 18px;

}