.btn{
    background-color: #1043A9;
    border-radius: 5px;
    font-family: Dana1;
    font-size: 22px;
    color: #FFFFFF;
    border: 3px solid #1043A9;

    transition: 0.1s ease-in-out all;

    padding: 2px 25px 2px 25px;
}
.btn:hover{
    border: 3px solid #4D76C8;

    background-color: #4D76C8 !important;
}

.btn:focus{
    border: 3px solid #95AEE8;
}
.btn:active{
    background-color: #CCD9F7 !important;
    color: #1043A9 !important;
    border: 3px solid #CCD9F7;
}
