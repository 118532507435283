.searchBarDiv{
height: 100%;
margin-right: 18px;
display: flex;
z-index: 0;
}
.searchBar{
    border-color: #1043A9;
    border-width: 3px;
    border-style: solid;
    border-radius: 5px;
    border-top-right-radius: 0px;
    width: 330px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    text-align: right;
    border-right: none;
    padding:3px 7px 3px 0px;
}
.searchBar:focus{
    border-color: #1043A9;
    outline: none;
    -webkit-box-shadow: 0px 0px 0px 4px rgba(204,217,247,0.99); 
    box-shadow: 0px 0px 0px 4px rgba(204,217,247,0.99);

}
    .searchBtn{
        align-content: center;
        background-color: #1043A9;
        padding: 3px 13px 0px 13px;
        border-top-right-radius: 5px;
        transition: 0.1s ease-in-out all;
        border-bottom-right-radius: 5px;
        z-index: 3;
        border: none;
    }
    .searchBtn:hover{
        background-color: #4D76C8 !important;

    }
    .clearBtn{
        position: absolute
    }
