.cardDiv{
    width: 100%;
    background-color: #ffffff;
    min-height: .01%;
    direction: rtl;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    text-align: right;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.rightSideDiv{
display: block;
width: 350px;

}
.rightSideDiv h3{
  cursor: pointer;
    font-size: 17px;
    color: #354063;
    font-family: Dana1;
    padding: 8px 20px 3px 0px;
}
.leftSideDiv{


}


.deleteIconDiv{
    

    padding-right: 8px;
}
.deleteIcon{
   transition: 0.1s ease-in-out all;

}
.deleteIcon:hover{
    color: #fd9c9c;
 }

 .editIconDiv{

    padding-right: 20px;

 }
 .editIcon{
    transition: 0.1s ease-in-out all;
 }
 .editIcon:hover{
    color: #6B779C;
 }





 .switch {
    position: relative;

    width: 51px;
    height: 25px;
    left: 0px;
    
    bottom: 0px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9e9e9e;
    border-radius: 0px;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .switchDiv{
    width: 50px;
      height: 100%;
      padding-right: 7px;
      }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 26px;
    border: rgb(155, 155, 155) 3px solid;
    
    
    background-color: #1043A9;
    -webkit-transition: .4s;
    transition: .4s;
  }

  
  input:checked + .slider {
    
    background-color: #1043A9;
   
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #b9c3e4;
    
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(-26px);
    -ms-transform: translateX(-26px);
    transform: translateX(-26px);
   
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  .checkboxDiv{
     

      margin-left: 10px;
  }



  .submitDate{
      margin-left: 0px;
  }

  .submitDate h3{
    color: #6e7aa1;
    font-size: 17px;
    padding-top: 10px;
    font-family: Dana2;
}




.profDiv{
width: 300px;

}
.profDiv h4{  
    cursor: pointer;
    font-size: 17px;
    display: inline-block;
    margin-right: 6px;
    color: #6e7aa1;
    font-family: Dana2;
}
.profImg{
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100%;
}





.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
border-collapse: separate;
border-spacing: 0 7px;
}
.table thead {
  width: 100%;
  color: #868686;
  border:3px solid;   
  font-family: Dana2;
  padding: 10px 5px 10px 10px !important;
}
.table thead th {

  padding: 5px 10px 0px 0px !important;
}




.table_bordered {
  border: none;
}
.phoneNumbers{
    display: inline-block;
}
.phoneNumbers h4{
    color: #354063;
    display: inline-block;
    padding:10px 2px 0px 2px ;
    height: 100%;
    font-family: Dana1;
    font-size: 18px;
}