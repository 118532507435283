/* ----------overal div style---------- */
.inndeDiv{
    margin-top: 70px;
    animation: openModal ease-in-out 0.5s forwards;


}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);

    }
    50% {
        opacity: 0;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

/* ----------route style---------- */
.routeBackground{
    background-color: #E6EDFD;
    height: 34px;
    border-radius: 5px;
    margin-top: 30px;
}

/* ----------photo gallery style---------- */
.selectedPhotoDiv{
    width: 100%;
height: 380px;
margin-bottom: 20px;
}

.subPhotoDiv{
width: 100%;
height: 100px;
}
.mainImg{
    object-fit: cover !important;
    height: auto;
    transition: transform .5s ease;
    width: 100%;
    border-radius: 5px;

    height: 100%;
}
.subImg{
    object-fit: cover !important;
    height: auto;
    transition: transform .5s ease;
    width: 100%;
    border-radius: 5px;

    height: 100%;
}

.subImg:hover{
    transform: scale(1.07);
}
.imageGalleryDiv{
    padding-left: 30px;
    margin-top: 20px;
}
.activeImage{
    object-fit: cover !important;
    height: auto;
    transition: transform .5s ease;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    border: 4px solid #4D76C8;
}

.activeImage:hover{
    transform: scale(1.07);
}


.colStyle{
    padding: none !important;
}


/* ---------product Brief style---------- */
.productBriefDiv{
    margin-top: 40px;
}
.productBriefDiv h4{
    font-size:35px ;
    color: #354063;
    font-family: Dana1;
    padding: 0px;
    margin: 0px;
}
.productTitleDiv{
    margin: 0px;
    padding: 0px;
}


.rateTextStyle{
    display: inline-block;
    font-size: 15px;
    color: #354063;
    margin-right: 10px;
}
.horizonDiv{
    color: #354063;
    display: inline-block;
    font-size: 20px;
    margin-right: 3px;
}
.commentText{
    display: inline-block;
    font-size: 15px;
    color: #354063;
    margin-right: 5px;
}
.line{
    width: 100%;
    height: 2px;
    background-color: #354063;
}
.featuresDiv h3{
    font-size: 25px;
    color: #1043A9;
    font-family: Dana1;
    margin-right: 10px;
    margin-bottom: 10px;
}
.featuresDiv{
    margin-top: 22px;
}
ul  {
    list-style-position:outside;
    font-size: 16px;
    margin-right: 0px;
    padding-right: 0px;
    margin-top: 12px;
    list-style-position: inside;
    
    
}
ul li {
    margin:0; padding:0;
}
.meterDiv{
    background-color: #354063;
    
    width: fit-content;
    border-radius: 4px;
    display: inline-block;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

}
.meterDiv h4{
    font-size: 17px;
    padding: 8px 10px 8px 10px;
    color: #EEEEEE;
}



.priceItSelfDiv{
    display: inline-block;
    padding: 0px 6px 0px 0px;

}
.priceItSelfDiv h4{
    display: inline-block;
    font-size: 17px;
    color: #303030;
    padding-top: 5px;

}

.priceDiv{
    display: inline-block;
    padding: 0px 0px 0px 7px;
    border: solid #dae2fc 3px;
    background-color: #f0f0f0;
    border-radius: 7px;
    
}
.priceUnit{
    margin-right: 5px;
    color: #303030;
    font-size: 17px;
    font-family: Dana1;
}


.purchaseDiv{
    margin-top: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 10px;
}
.purchaseTitle h4{
    font-size: 23px;
    color: #354063;
    font-family: Dana1;
    margin-right: 14px;
    margin-bottom: 16px;
    margin-top: 6px;

}
.contactBtn{
    width: 100%;
    background-color: #1043A9;
    padding: 5px 0px 5px 0px;
    color: #EEEEEE;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    transition: 0.1s ease-in-out all;
    font-family: Dana1;
    border: 5px solid #1043A9;

}
.contactBtn:hover{
background-color: #4D76C8;
border: 5px solid #4D76C8;

}
.contactBtn:active{
    background-color: #CCD9F7;
    color: #1043A9;
    
    border: 5px solid #CCD9F7;

    
    }
.purchaseBtn{
    margin-top: 10px;
}

    .whatsAppBtn{
        width: 100%;
        background-color:#00CC81;
        padding: 5px 0px 5px 0px;
        color: #202020;
        font-size: 20px;
        border: none;
        margin-top: 5px;
        border-radius: 5px;
        transition: 0.1s ease-in-out all;
        font-family: Dana1;
        border: 5px solid #00CC81;
    
    }
    .whatsAppBtn:hover{
    background-color: #48ecb0;
    border: 5px solid #48ecb0;
    
    }
    .whatsAppBtn:active{
        background-color: #a7eed4;
        color: #1d1d1d;
        
        border: 5px solid #a7eed4;
    
        
        }


.stockStyle{
    display: inline-block;
    padding: 0px 0px 0px 7px;
    border: none;
    background-color: #e3e8fc;
    border-radius: 7px;
    float: left;
    opacity: 1;
    margin-top: 5px;
}
.stockStyle h4{
    color: #354063;
    font-size: 13px;
    padding: 8px 3px 8px 6px;
    display: inline-block;
}
.availableSurfaceDiv{
    font-size: 13px !important;
    color: #354063 !important;
    font-family: Dana2 !important;
}
.availableSurfaceDiv span{

    color: #cc0000;
}

/* ---------product navigation style---------- */

.unortherListActive{

    border: 5px solid #1043A9;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
}
.unortherList{
    background-color: #E6EDFD;
    list-style: none;
    display: flex;
    margin-top: 0px;


}
.unortherList li{
    cursor: pointer;
    transition: 0.1s ease-in-out all;
    font-size: 18px;
    padding: 10px 20px 10px 20px;
    list-style: none;
    
}



.unortherList li:hover{
    background-color: #CCD9F7 ;

    
}













/*------------- table style -------------*/
.featuresTable{
    margin-top: 30px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
     

}
.styled_table{
    width: 100%;
    margin-top: 10px;
    margin-right: 20px;
    text-align: right;
}
.featuresListUl{
    padding: 0;
    list-style: none;
    width: 100%;
    margin-bottom: 25px;
}
.featuresListUl li{
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: -10px;
    margin-bottom: 10px;
    line-height: 50px;
}

.itemValue{
    font-size: 16px;
    font-family: Dana1;
    color: #181818;
}

.itemName{
    font-size: 16px;
    font-family: Dana2;
    color: #757575;
    margin-left: 30px;
    width: 200px;
}


  /*------------- review section -------------*/
.reviewDiv{
    margin-top: 20px;
}
.reviewContentDiv{
    margin-top: 10px;

}
.reviewDiv p{
font-size: 1rem;
line-height: 2.1;
color: #232933;
font-family: Dana2;
text-align: justify;
}
.reviewDiv h4{
    font-family: Dana1;
    font-size: 1.7rem;
    margin-bottom: 10px;
    color: #1043A9;
}
.reviewDiv img{
    border-radius: 2px;
    object-fit: cover !important;
    height: auto;
    width: 100%;
    max-width:700px;
}
.reviewHeaderDiv{
    text-align: center;
}
.reviewHeaderDiv h3{
    font-family: Dana1;
    padding-bottom: 2px;
    color: #354063;
    display: inline-block;
    border:0px solid #354063;
    border-bottom-width: 3px;
}
.reviewHeaderDiv h2{
    font-family: Dana1;
    color: #7a7d88;
    padding: 0px;
    margin-top: 0px;
    font-size: 12px;
}





  /*------------- comment section -------------*/
  .commentSectionDiv{
    text-align: right;
}

.commentSectionHeaderDiv{
    margin-top: 20px;
    display: inline-block;
    margin-right: 30px;
    width: 100%;
}

.commentBoxDiv{
    margin-top: 0px;
    padding: 0px;
}
.commentDiv{
    margin-top: 20px;
}
.theRateItSelfDiv{
    float: left;
    text-align: right;

}
.theRateItSelfDiv h5{
    color: #354063;
    font-size: 15px;
    font-family: Dana1;

}
.theRateItSelfDiv h5 span{
    color: #1043A9;
    font-size: 35px;
    font-family: Dana1;

}
.theHeaderItSelfDiv{
    
    float: right;
    text-align: right;
}


.loaderDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;

}