.App{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
input[type="radio"]{
    display: none;
}
.star{
    cursor: pointer;
    transition: color 200ms;
}
