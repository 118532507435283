.productCardDiv{
    width: 100%;
    height: 390px;
    background-color: #EFEFEF;
    border-radius: 15px;
    transition: 0.3s  ease-in-out all;
}
.productCardDiv:hover{
    background-color: #e6e6e6;
    filter: drop-shadow(0 0 0.35rem #1043A9);

}
.productCardDiv img{
    width: 100%;
    height: 280px;
    background-repeat: none;
    background-position: center;
    object-fit: cover;
    border-radius: 15px;
    border: #CCD9F7 4px solid;
}
.productCardDiv h4{
    font-size: 17px;
    text-align: right;
    font-family: Dana1;
    padding: 10px 17px 0px 17px;
    color: #010102;
    line-height: 23px;
    display: inline-block;
}
.cardInfo{
    width: 100%;
    
}
.measure h4{
    padding: 4px 8px 4px 8px;
    color: #EFEFEF;
    border-radius: 7px;
    font-size: 14px;
    display: inline-block;
    background-color: #354063;
}
.priceDiv h5{
    font-size: 16px;
    padding-right: 4px;
}
.priceDiv{
    display: flex;
    float: left;
    margin-top: 9px;
    
}
.measure{
    padding:2px 12px 0px 6px;
    display: inline-block;
}

.ratingStar{
    display: flex;
    float: left;
    padding-top: 9px;
    padding-left: 12px;
}
.ratingStar h4{
    display: inline-block;
    padding: 3px 0px 0px 2px;
    font-size: 16px;

}
.productNameDiv{
    height: 66px;
}