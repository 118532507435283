.wapper{
    padding: 10px 150px 0px 150px;
    animation: openModal ease-in-out 0.5s forwards;

}

.loaderDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
}
.navigation{
    position: sticky !important;
    top: 0px !important;
    z-index: 8;
    animation: openModal ease-in-out 0.5s forwards;
    
}
.navigation nav{

    margin-bottom: 20px;
    border-bottom: rgb(24, 24, 24) 2px dashed ;
    background-color: rgba(0, 0, 0, 0) !important;
}
@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);

    }
    50% {
        opacity: 0;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}


@media (max-width: 768px) {
    .wapper{
        padding: 10px 50px 0px 50px;
    }
 }
 @media (max-width: 992px) {
    .wapper{
        padding: 10px 50px 0px 50px;
    }
}
@media (max-width: 1200px) {

    .wapper{
        padding: 10px 30px 0px 30px;
    }
}

