.backDrop{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 13;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.5s ease-in-out all;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.644);
}

.modalDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 13;
    width: 100%;
    height: 100%;
}
.fadeIn{

    animation: openModal ease-in-out 0.5s forwards;
}
.fadeOut{
    opacity: 0;
    transform: translateY(-100%);
}
@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);

    }
    50% {
        opacity: 0;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.modalBoarder{
    width: auto;
    max-width: 100%;
    transition: 0.5s ease-in-out all;
    z-index: 16;
    height: auto;
    padding: 0px 30px 0px 30px;
    max-height: 100%;
}
.priviewImageStyle{
    width: auto;
    border: none;
    height: 500px;
    object-fit: cover;
}

.modalSymbol{
    display: flex;
    align-items: center;
    text-align: center;
    height: 55%;
}
.msg{
    text-align: center;
}
.msg h3{
 color: #354063;
 font-family: Dana1;
 font-size: 27px;
}
.btnsDiv{
    margin-top: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    
    
}
.btnDiv{
    margin-left: auto;
    margin-right: 10px;
}
.btnDiv2{
    margin-left: 10px;
    margin-right: auto;
}
.scaleIn{ transform: scale(1.0);}
.scaleOut{transform: scale(0.0);}



.dismissBtn{
    position: absolute;
    right: 10px;
    top: 10px;
}